<template>
  <div id="user-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-title></v-card-title>
      <v-card-text>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="12">
                <p>
                  <span class="text-danger">*</span> indicates required field
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Plan Name</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="PlanIdOptions"
                  :rules="PlanIdRules"
                  v-model="PlanId"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Zone</h6>
                <v-autocomplete
                  @change="getTableNameOptions"
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Table Name</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="TableNameOptions"
                  :rules="TableNameRules"
                  v-model="TableName"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> MemberShip Id</h6>
                <v-text-field
                  v-model="MemberShipId"
                  placeholder="MemberShip id"
                  :rules="MemberShipIdRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col align="center" cols="12" sm="6" lg="4" md="4">
                <v-btn
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  class="mr-4 mt-8 white--text"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="MemberId > 0">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img
                  width="100"
                  height="auto"
                  :src="MemberDetails.MemberImage"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Name</label>
                <h5>{{ MemberDetails.Fullname }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Membership Id</label>
                <h5>{{ MemberDetails.MembershipId }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Zone</label>
                <h5>{{ MemberDetails.ZoneName }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Lom</label>
                <h5>{{ MemberDetails.LomName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Designation</label>
                <h5>{{ MemberDetails.MemberDesignation }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Contact Number</label>
                <h5>{{ MemberDetails.MobileNo }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Email</label>
                <h5>{{ MemberDetails.EmailId }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Address</label>
                <h5>{{ MemberDetails.AddressTxt }}</h5>
              </v-col>
            </v-row>

            <v-row wrap v-if="MemberId > 0">
              <v-col cols="12" sm="6" md="3">
                <h4>Date of Birth</h4>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="MemberDetails.DOB"
                      label=" Date of Birth"
                      hint="Select a Date of Birth"
                      persistent-hint
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="MemberDetails.DOB"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Profile Update</h4>
                <v-chip
                  :color="`${
                    MemberDetails.ProfileUpdate == 1 ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{
                    MemberDetails.ProfileUpdate == 1 ? "Completed" : "Pending"
                  }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Photo Update</h4>
                <v-chip
                  :color="`${
                    MemberDetails.PhotoUpdate ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ MemberDetails.PhotoUpdate ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberPhotoPrompt = true"
                  v-if="MemberDetails.PhotoUpdate == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Age Proof Document Upload</h4>
                <v-chip
                  :color="`${
                    MemberDetails.DocumentUpdate ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ MemberDetails.DocumentUpdate ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberDocumentPrompt = true"
                  v-if="MemberDetails.DocumentUpdate == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
            </v-row>
            <br /><br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="#1db954"
                  class="font-size-h6 px-4 mr-3 white--text"
                >
                  Submit
                </v-btn>
                <v-btn
                  @click="closePrompt"
                  elevation="30"
                  rounded
                  medium
                  color="#a52a2a"
                  class="font-size-h6 px-4 mr-3 white--text"
                >
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <upload-selected-member-document
        :record="member"
        v-if="memberDocumentPrompt"
        :showDialog="memberDocumentPrompt"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></upload-selected-member-document>
    </v-dialog>
    <v-dialog v-model="memberPhotoPrompt" persistent scrollable>
      <upload-selected-member-photo
        :record="member"
        v-if="memberPhotoPrompt"
        :showDialog="memberPhotoPrompt"
        @hideDialog="hideMemberPhotoPrompt($event)"
      ></upload-selected-member-photo>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import UploadSelectedMemberDocument from "@/view/pages/members/UploadSelectedMemberDocument.vue";
import UploadSelectedMemberPhoto from "@/view/pages/members/UploadSelectedMemberPhoto.vue";

export default {
  mixins: [common],
  components: {
    UploadSelectedMemberDocument,
    UploadSelectedMemberPhoto,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: false,

      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      ResultFlag: false,

      memberDocumentPrompt: false,
      memberPhotoPrompt: false,

      member: {},
      menu1: false,
      MemberDetails: {},
      MemberId: "",

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      TableNameRules: [(v) => !!v || "Table Name is required"],
      TableName: "",
      TableNameOptions: [],

      PlanIdRules: [(v) => !!v || "Plan Id is required"],
      PlanId: "",
      PlanIdOptions: [],

      MemberShipIdRules: [(v) => !!v || "MemberShip id is required"],
      MemberShipId: "",

      PageInfo: {},
      PageTitle: "Create Table Member Details",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath =
          server_url + "assets/sample/jcom_table_member_upload_sample.xlsx";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;
      }
    },
    MemberDetails: function () {
      console.log("watch MemberDetails");
      console.log("valid1" + this.valid1);
      var n1 = Object.keys(this.MemberDetails).length;
      console.log("MemberDetails" + n1);
      this.valid2 = n1 > 0 && this.valid1 ? true : false;
      console.log("valid2" + this.valid2);
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_members_upload",
        Action: "create",
      };
      // this.getPageDetails("PageInfo", {}, url1, condition1);
      this.pageData();
      this.LoadingFlag = false;
    },
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        this.MemberDetails.DocumentUpdate = true;
      }
    },
    hideMemberPhotoPrompt(flag) {
      console.log("hideMemberPhotoPrompt called");
      console.log({ flag });
      this.memberPhotoPrompt = false;
      if (flag) {
        this.MemberDetails.PhotoUpdate = true;
      }
    },
    pageData() {
      console.log("pageData called");
      this.getZoneCodeOptions();
      this.getPlanOptions();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/jcom/table/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableNameOptions() {
      console.log("getTableNameOptions called");
      var selectbox1_source = "TableName";
      var selectbox1_destination = "TableNameOptions";
      var selectbox1_url = "api/jcom/table/options";

      var Zone = this.ZoneCode;

      var selectbox1_conditions_array = {
        UserInterface: 2,
        Zone: Zone,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getPlanOptions() {
      console.log("getPlanOptions called");
      var selectbox1_source = "PlanId";
      var selectbox1_destination = "PlanIdOptions";
      var selectbox1_url = "api/jcom/plan/options";

      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");
      var MemberShipId = this.MemberShipId;
      console.log("MemberShipId=" + MemberShipId);
      if (MemberShipId != "") {
        this.SearchFlag = true;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: this.MemberShipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.MemberDetails = records;
              thisIns.MemberId = records.MemberId;

              thisIns.member = {
                value: records.MemberId,
                member_name: records.FirstName,
                membership_id: records.MembershipId,
              };

              console.log("member" + JSON.stringify(thisIns.member));
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Membership Id is required";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;
      var PlanId = this.PlanId == "" ? null : this.PlanId;
      var ZoneId = this.ZoneCode == "" ? null : this.ZoneCode;
      var TableId = this.TableName == "" ? null : this.TableName;
      var MemberShipId = this.MemberShipId == "" ? null : this.MemberShipId;

      console.log({ PlanId });
      console.log({ ZoneId });
      console.log({ TableId });
      console.log({ MemberShipId });
      if (validate1) {
        var upload = {
          UserInterface: 1,
          Plan: PlanId,
          Zone: ZoneId,
          Table: TableId,
          MemberShipId: MemberShipId,
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/membership/table-member-create";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
</style>