<template>
  <div id="jcom-table-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>

                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>JCOM Year</h6>
                  <h6>{{ record.TableYearDisplayTxt }}</h6>
                </v-col>

                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>JCOM Member</h6>
                  <h6>{{ record.MemberNameTxt }}</h6>
                </v-col>

                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>Order Date</h6>
                  <h6>{{ record.OrderDateTxt }}</h6>
                </v-col>

                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>Order Toatal Amount</h6>
                  <h6>{{ record.OrderTotalAmount }}</h6>
                </v-col>
              </v-row>
              <v-row wrap v-if="TableEditFlag">
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> JCOM Zone</h6>
                  <v-autocomplete
                    @change="getTableTypeOptions"
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Select Table Type</h6>
                  <v-autocomplete
                    @change="getTableNameOptions"
                    :reduce="(option) => option.value"
                    :items="TableTypeOptions"
                    :rules="TableTypeRules"
                    v-model="TableType"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Table Name</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="TableNameOptions"
                    :rules="TableNameRules"
                    v-model="TableName"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row wrap v-if="record.ProfessionDetailsCount > 0">
                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Business Type</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="MemberProfessionIdOptionsLoading"
                    :items="MemberProfessionIdOptions"
                    :rules="MemberProfessionIdRules"
                    v-model="MemberProfessionId"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap v-else>
                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Occupation</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="OccupationTypeOptionsLoading"
                    :items="OccupationTypeOptions"
                    :rules="OccupationTypeRules"
                    v-model="OccupationType"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Business Type</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="IndustryTypeOptionsLoading"
                    :items="IndustryTypeOptions"
                    :rules="IndustryTypeRules"
                    v-model="IndustryType"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Company Name</h6>
                  <v-text-field
                    v-model="CompanyName"
                    :rules="CompanyNameRules"
                    placeholder="Company Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#a52a2a"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      TableEditFlag: false,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      TableTypeRules: [(v) => !!v || "Table Type is required"],
      TableType: "",
      TableTypeOptions: [],
      TableTypeOptionsLoading: false,

      TableNameRules: [(v) => !!v || "Table Name is required"],
      TableName: "",
      TableNameOptions: [],
      TableNameOptionsLoading: false,

      OccupationTypeRules: [(v) => !!v || "Occupation Type is required"],
      OccupationType: "",
      OccupationTypeOptions: [],
      OccupationTypeOptionsLoading: false,

      IndustryTypeRules: [(v) => !!v || "Business type is required"],
      IndustryType: "",
      IndustryTypeOptions: [],
      IndustryTypeOptionsLoading: false,

      MemberProfessionIdRules: [(v) => !!v || "Business type is required"],
      MemberProfessionId: "",
      MemberProfessionIdOptions: [],
      MemberProfessionIdOptionsLoading: false,

      ProfessionDetailsCount: 0,

      TableId: 0,
      record: {},

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "MembershipId is required"],

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      TableNameRules: [(v) => !!v || "Table Name is required"],
      TableName: "",

      CompanyName: "",
      CompanyNameRules: [(v) => !!v || "Company Name is required"],

      LaunchDate: "",
      LaunchDateRules: [(v) => !!v || "Launch date is required"],
      menu1: false,

      ActiveStatus: 1,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.LaunchDate = todayDate;

        this.PageTitle = tr.PageTitle;
        this.TableEditFlag = tr.EditFlag2;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    TableTypeOptions: function () {
      console.log("watch TableTypeOptions");
      this.TableTypeOptionsLoading = false;
    },
    TableNameOptions: function () {
      console.log("watch TableNameOptions");
      this.TableNameOptionsLoading = false;
    },
    OccupationTypeOptions: function () {
      console.log("watch OccupationTypeOptions");
      this.LoadingFlag = false;
      this.OccupationTypeOptionsLoading = false;
    },
    IndustryTypeOptions: function () {
      console.log("watch IndustryTypeOptions");
      this.LoadingFlag = false;
      this.IndustryTypeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_members",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;
    },
    pageData() {
      this.getZoneCodeOptions();
      this.getOccupationTypeOptions();
      this.getIndustryTypeOptions();
      this.getTableRecords();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getTableTypeOptions() {
      console.log("getTableTypeOptions called");
      var selectbox1_source = "TableType";
      var selectbox1_destination = "TableTypeOptions";
      var selectbox1_url = "api/jcom/table-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableNameOptions() {
      console.log("getTableNameOptions called");
      var ZoneCode = this.ZoneCode;
      var TableType = this.TableType;
      console.log("ZoneCode=" + ZoneCode + ", TableType=" + TableType);
      if (ZoneCode != "" && TableType != "") {
        var selectbox1_source = "TableName";
        var selectbox1_destination = "TableNameOptions";
        var selectbox1_url = "api/jcom/table/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Zone: ZoneCode,
          TableType: TableType,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (TableType == "") {
          message += "Table Type should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getOccupationTypeOptions() {
      console.log("getOccupationTypeOptions called");
      this.OccupationTypeOptionsLoading = true;
      var selectbox1_source = "OccupationType";
      var selectbox1_destination = "OccupationTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 5,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getIndustryTypeOptions() {
      console.log("getIndustryTypeOptions called");
      this.IndustryTypeOptionsLoading = true;
      var selectbox1_source = "IndustryType";
      var selectbox1_destination = "IndustryTypeOptions";
      var selectbox1_url = "api/business-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberProfessionIdOptions() {
      console.log("getMemberProfessionIdOptions called");
      this.IndustryTypeOptionsLoading = true;
      var selectbox1_source = "MemberProfessionId";
      var selectbox1_destination = "MemberProfessionIdOptions";
      var selectbox1_url = "api/members-profession/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        Member: this.MemberId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");
      var record = this.recordData;
      console.log({ record });
      this.record = record;
      this.MembershipId = record.MembershipId;
      this.MemberId = record.MemberId;
      this.ZoneCode = record.TableZoneId;
      this.getTableTypeOptions();
      this.TableType = record.TableTypeId;
      this.getTableNameOptions();
      this.TableName = record.TableId;
      this.MemberProfessionId = record.MemberProfessionId;
      this.ProfessionDetailsCount = record.ProfessionDetailsCount;
      if (this.ProfessionDetailsCount > 0) {
        this.getMemberProfessionIdOptions();
      }
      this.CompanyName = record.CompanyName;
    },
    submitForm() {
      console.log("submitForm is called");

      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      this.progessStart = 1;
      this.SubmitFlag = true;
      this.ResultFlag = false;

      var MembershipId = this.MembershipId;
      var OccupationType = this.OccupationType;
      var IndustryType = this.IndustryType;
      var TableName = this.TableName;
      var CompanyName = this.CompanyName;
      var MemberProfessionId = this.MemberProfessionId;

      console.log(
        "MembershipId = " +
          MembershipId +
          ", OccupationType = " +
          OccupationType +
          ", IndustryType = " +
          IndustryType +
          ", TableName = " +
          TableName +
          ", MemberProfessionId = " +
          MemberProfessionId
      );

      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));

      if (MembershipId > 0) {
        var upload = {
          UserInterface: 1,
          JcomYearwiseMembershipId: recordData.JcomYearwiseMembershipId,
          Year: recordData.TableYearId,
          Zone: recordData.TableZoneId,
          Table: TableName,
          TableType: recordData.TableTypeId,
          PlanId: recordData.PlanId,
          OccupationType: OccupationType,
          IndustryType: IndustryType,
          CompanyName: CompanyName,
          MemberProfessionId: MemberProfessionId,
          MembershipId: recordData.MembershipId,
          MemberId: recordData.MemberId,
          OrderId: recordData.OrderId,
          TransferFlag: true,
        };

        console.log("upload=" + JSON.stringify(upload));

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;

        var add_url = server_url + "api/jcom/membership/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);
            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>